.card-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .custom-card {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
  }
  
  .custom-card:hover {
    background-color: #f5f5f5;
  }
  
  .selected {
    border-color: #007bff; /* Highlight color for selected card */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Optional: Add shadow for selected effect */
  }
  
#subdoc1{
  pre{
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
  }
  h5{
    margin-top: 16px !important;
  }
}
.np{
  &.custom-bad{
    padding: unset;
    >div{
      background-color: #ff0000;
    }
  }
  &.custom-warn{
    padding: unset;
    >div{
      background-color: #ff8f00;
    }
  }
  &.custom-safe{
    padding: unset;
    >div{
      background-color: #0d6efd;
    }
  }
}