.docs-api-key-box{
    flex-direction: row;
    >div{
        display: flex;
        flex-direction: row;
        gap: 8px;
    }
}
@media (max-width: 675px) {
    .docs-api-key-box{
        flex-direction: column;
        >div{
            >button{
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: center;
                width: 100%;
            }
        }
    }
}
@media (max-width: 440px) {
    .docs-api-key-box{
        >div{
            flex-direction: column;
        }
    }
}