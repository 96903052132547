@media (max-width: 675px) {
  .billingRow {
    flex-direction: column;
  }
  .billingRow button {
    width: 100%;
    margin-right: unset !important;
    margin-left: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: unset;
  }
  .billingHeader {
    display: flex;
    flex-direction: column;
  }
  .billingHeader .col {
    display: flex;
  }
}
